<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card color="transparent ma-0 pa-0" elevation="0">
        <v-card-actions class="ma-0 pa-0">
          <v-spacer />
          <v-btn @click="Smartlinks.actions.unselectItem()" class="ma-1" depressed small>
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('commons.operation.back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card class="mx-auto mt-5 elevation-1" outlined>
        <v-row justify="space-between">
          <v-col cols="12">
            <v-card class="mx-auto elevation-0" color="transparent" outlined>
              <v-card-title class="title font-weight-light mt-3 mb-0 pt-0">
                <v-spacer />
                <v-chip depressed label class="font-weight-regular" color="primary">
                  Smartlink ID: {{ Smartlinks.state.item._id }}
                </v-chip>
              </v-card-title>
              <v-card-title class="heading font-weight-light mt-3 mb-0 pt-0">
                {{ Smartlinks.state.item.name }}
              </v-card-title>
              <v-card-text
                v-if="Smartlinks.state.application"
                class="title font-weight-light mt-3 mb-0 pt-0"
              >
                <v-spacer />
                <v-dialog v-model="TAC_Dialog" max-width="800px">
                  <v-card>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      {{ $t('commons.titles.TermsConditions') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <div v-html="Smartlinks.state.item.termsAndConditions" />
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="TAC_Dialog = false">
                        Disagree
                      </v-btn>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="
                          Smartlinks.actions.runApplication({
                            survey: Smartlinks.state.application.survey,
                            type: Smartlinks.state.application.type,
                          }) && (TAC_Dialog = false)
                        "
                      >
                        Agree
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="SURVEY_Dialog" max-width="800px">
                  <v-card>
                    <v-card-title
                      class="text-capitalize font-weight-light"
                      v-if="Smartlinks.state.application.survey"
                    >
                      <v-spacer />
                      {{ $t('commons.titles.requestApproval') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" v-if="Smartlinks.state.application.survey" />
                    <v-card-text v-if="Smartlinks.state.application.survey">
                      <v-form v-model="valid">
                        <v-container>
                          <v-layout wrap>
                            <v-flex
                              xs12
                              v-for="(question, index) in Smartlinks.state.application.survey"
                              :key="index"
                            >
                              <v-card class="elevation-0 mb-1">
                                <v-card-title class="text-md-left subheading font-weight-regular">
                                  {{ question.title }}
                                </v-card-title>
                                <v-card-text class="text-md-left">
                                  <v-text-field
                                    v-if="question.type === 'Text'"
                                    :name="question.flag"
                                    v-model="question.value"
                                    clearable
                                    solo-inverted
                                    flat
                                    required
                                    :rules="[(v) => !!v || 'The answer is required']"
                                  />
                                  <v-textarea
                                    v-else-if="question.type === 'Textarea'"
                                    :name="question.flag"
                                    v-model="question.value"
                                    auto-grow
                                    solo-inverted
                                    flat
                                    required
                                    :rules="[(v) => !!v || 'The answer is required']"
                                  />
                                  <v-select
                                    v-else-if="question.type === 'Select'"
                                    :label="question.title"
                                    v-model="question.value"
                                    :items="question.items"
                                    solo-inverted
                                    flat
                                    required
                                    :rules="[(v) => !!v || 'The answer is required']"
                                  />
                                </v-card-text>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card-text>
                    <v-card-title class="text-capitalize font-weight-light">
                      <v-spacer />
                      {{ $t('commons.titles.TermsConditions') }}
                      <v-spacer />
                    </v-card-title>
                    <v-divider class="mx-10" />
                    <v-card-text>
                      <div v-html="Smartlinks.state.item.termsAndConditions" />
                    </v-card-text>
                    <v-divider class="mx-10" />
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="SURVEY_Dialog = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        :disabled="!valid"
                        color="green darken-1"
                        text
                        @click="
                          Smartlinks.actions.runApplication({
                            survey: Smartlinks.state.application.survey,
                            type: Smartlinks.state.application.type,
                          }) && (SURVEY_Dialog = false)
                        "
                      >
                        Agree & Request approval
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  v-if="
                    Smartlinks.state.application.status &&
                    Smartlinks.state.application.type == 'approved'
                  "
                  @click.stop="
                    Smartlinks.state.application.forceTermsAndConditions
                      ? (TAC_Dialog = true)
                      : Smartlinks.actions.runApplication({
                          survey: Smartlinks.state.application.survey,
                          type: Smartlinks.state.application.type,
                        })
                  "
                  depressed
                  label
                  class="font-weight-regular"
                  color="green"
                  dark
                  block
                  >Get tracking link</v-btn
                >
                <v-btn
                  v-else-if="
                    Smartlinks.state.application.status &&
                    Smartlinks.state.application.type == 'unapproved'
                  "
                  @click.stop="SURVEY_Dialog = true"
                  depressed
                  label
                  class="font-weight-regular"
                  color="blue lighten-1"
                  dark
                  block
                  >Apply to run</v-btn
                >
                <v-btn
                  v-else-if="'pending' == Smartlinks.state.application._id"
                  depressed
                  label
                  class="font-weight-regular"
                  color="red lighten-1"
                  dark
                  block
                  >Pending approval status</v-btn
                >
                <v-btn
                  v-else-if="'rejected' == Smartlinks.state.application._id"
                  depressed
                  label
                  class="font-weight-regular"
                  color="red lighten-1"
                  dark
                  block
                  >Your application to the offer has been rejected</v-btn
                >
                <v-window v-else-if="Smartlinks.state.application._id" show-arrows class="mx-auto">
                  <v-window-item>
                    <v-card color="transparent" elevation="0" height="200">
                      <v-row class="fill-height" align="center" justify="center">
                        <v-card-title class="subheading ma-0 pa-0">Tracking link</v-card-title>
                        <v-card-text class="text-center m-1 p-1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-on="on"
                                style="max-width: 800px"
                                depressed
                                label
                                class="font-weight-regular scrollable-chip"
                                color="blue lighten-1"
                                dark
                                @click="
                                  copyToClipboard(
                                    `https://affcpatrk.com/link?id=${Smartlinks.state.application._id}${affSubsParams}`
                                  )
                                "
                              >
                                <span>Copy</span>
                                <v-icon class="mx-3">mdi-clipboard</v-icon>
                                {{
                                  `https://affcpatrk.com/link?id=${Smartlinks.state.application._id}${affSubsParams}`
                                }}
                              </v-chip>
                            </template>
                            <span>{{
                              `https://affcpatrk.com/link?id=${Smartlinks.state.application._id}${affSubsParams}`
                            }}</span>
                          </v-tooltip>
                        </v-card-text>
                      </v-row>
                    </v-card>
                  </v-window-item>
                </v-window>
                <v-spacer />
              </v-card-text>
              <v-card-text v-else>
                <v-progress-linear indeterminate height="33" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            v-if="
              Smartlinks.state.application &&
              !Smartlinks.state.application.status &&
              Smartlinks.state.application.type == 'exists' &&
              Smartlinks.state.application._id != 'pending' &&
              Smartlinks.state.application._id != 'rejected'
            "
            align="center"
          >
            <AffSub @input="affSubsParams = $event" />
          </v-col>
        </v-row>

        <v-card-actions class="ma-0 pt-0">
          <v-spacer />
          <v-btn @click="Smartlinks.actions.unselectItem()" class="ma-1" depressed small>
            <v-icon>mdi-chevron-left</v-icon>
            {{ $t('commons.operation.back') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { inject, watch, computed, reactive } from '@vue/composition-api';
import AffSub from '../../Components/AffSub.vue';

export default {
  name: 'smartlink-preview',
  components: {
    AffSub,
  },
  props: {},
  setup(props, context) {
    const Smartlinks = inject('Smartlinks');

    const self = reactive({
      search: '',
      valid: false,
      TAC_Dialog: false,
      SURVEY_Dialog: false,
      affSubsParams: '',
    });
    const headers = [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
        width: 100,
      },
      { text: 'Creative', value: 'name' },
      { text: 'Type', value: 'type', align: 'start', width: 200 },
      { text: 'Action', value: 'link', align: 'start', width: 100, filterable: false },
    ];

    const copyToClipboard = (text) => {
      if (navigator.clipboard) navigator.clipboard.writeText(text);
    };

    return { Smartlinks, ...self, headers, copyToClipboard };
  },
};
</script>
<style>
.scrollable-chip {
  overflow-x: auto;
}
.scrollable-chip::-webkit-scrollbar {
  height: 5px;
}
.scrollable-chip::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
</style>

<template>
  <v-container fluid class="pr-0 pl-0 elevation-0">
    <smartlink-detail v-if="Smartlinks.state.isSelected" />
    <smartlink-list v-else />
  </v-container>
</template>
<script>
import { inject, onUnmounted } from '@vue/composition-api';
import SmartlinkList from './Smartlinks/SmartlinkList';
import SmartlinkDetail from './Smartlinks/SmartlinkDetail';

export default {
  name: 'affiliate-smartlinks',
  components: { SmartlinkList, SmartlinkDetail },
  setup(props, context) {
    const Application = inject('Application');
    const Smartlinks = inject('Smartlinks');
    onUnmounted(async () => {
      Smartlinks.actions.loadItems();
    });
    if (context.root.$route.params._smartlink) {
      Smartlinks.actions.loadAndSelectItem(context.root.$route.params._id);
    }
    return { Application, Smartlinks };
  },
};
</script>

<template>
  <v-card :loading="loading" elevation="0" color="transparent">
    <v-data-iterator
      v-bind:items="items"
      v-bind:loading="loading"
      :options.sync="pagination"
      :server-items-length="serverItemsLength"
      :item-key="itemKey"
      :rows-per-page="itemsList()"
      :footer-props="{
        itemsPerPageOptions: itemsPerPageOptions,
        showFirstLastPage: true,
      }"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
      <template v-slot:loading>
        <v-row class="fill-height" align="center" justify="center">
          <v-progress-circular indeterminate width="1" size="40" color="grey"></v-progress-circular>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card>
</template>
<script>
export default {
  name: 'data-list',
  data: () => ({
    pagination: {
      itemsPerPage: 8,
    },
  }),
  props: {
    itemKey: {
      type: String,
      default: 'id',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [8, 12, 16, 20, 24, 28],
    },
    serverItemsLength: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemsNewList: {
      type: Number,
    },
    sortBy: String | undefined,
    sortDesc: Boolean | undefined,
  },
  methods: {
    paginate(args) {
      this.$emit('paginate', args);
    },
    resetPagination() {
      this.pagination.page = 1;
    },
    itemsList() {
      let items;
      if (this.pagination.itemsPerPage == 8) {
        this.pagination.itemsPerPage = this.itemsNewList;
        items = this.itemsNewList;
      } else {
        items = this.itemsNewList;
      }
      return items;
    },
  },
  watch: {
    pagination: {
      handler() {
        const { page, itemsPerPage, sortBy, sortDesc } = this.pagination;
        this.paginate({
          sortBy: this.sortBy || (sortBy.length === 1 ? sortBy[0] : null),
          descending: this.sortDesc || (sortDesc.length === 1 ? sortDesc[0] : null),
          page: page - 1,
          rowsPerPage: itemsPerPage,
        });
      },
      deep: true,
    },
  },
};
</script>

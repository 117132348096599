<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      class="mt-15 mx-auto"
      :elevation="hover ? 1 : 0"
      open-delay="200"
      max-width="450"
      :class="{ 'on-hover': hover }"
      outlined
    >
      <v-card-text>
        <div class="subheading font-weight-light grey--text">ID: {{ item._id }}</div>
        <div class="subtitle-2 mb-2" style="min-height: 40px">{{ item.name }}</div>
        <v-chip-group v-if="item.categories" class="ma-0" :show-arrows="true">
          <v-chip v-for="(category, index) in item.categories" :key="index" label small>
            {{ category }}
          </v-chip>
        </v-chip-group>
        <v-divider class="mx-2"></v-divider>
      </v-card-text>
      <v-card-actions class="ma-0 pt-0">
        <v-chip small label :style="colorStatus(item)" class="font-weight-bold">
          {{ formatedItemApplicationStatus }}
        </v-chip>
        <v-spacer />
        <v-chip @click="select" class="ma-2 text-capitalize" depressed small label>
          {{ $t('commons.titles.detailsTrackinglink') }}
          <v-icon right>mdi-chevron-right</v-icon>
        </v-chip>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>
<script>
export default {
  name: 'smartlink-preview',
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const select = () => context.emit('select');

    return { select };
  },
  computed: {
    formatedItemApplicationStatus() {
      if (this.item.applicationStatus === 'Apply_To_Run') {
        return 'Apply to run';
      }
      return this.item.applicationStatus;
    },
  },
  methods: {
    colorStatus(item) {
      switch (item.applicationStatus) {
        case 'Approved':
          return 'backgroundColor:#c0f3b6; color:#009600;';
        case 'Applied':
          return 'backgroundColor:#ffdc8b; color:#ff7300';
        case 'Rejected':
          return 'backgroundColor:#e1e1e1; color:#6e6e6e';
        case 'Apply_To_Run':
          return 'backgroundColor:#c3ebff; color:#0082ff;';
        default:
          return 'backgroundColor:#000000; color:#ffffff';
      }
    },
  },
};
</script>

<template>
  <div class="border">
    <v-card>
      <v-card-title class="subtitle-1 font-weight-light"> Add Sub IDs </v-card-title>
      <v-card-text>
        <v-text-field
          v-for="(value, prop) in affSubs"
          :key="prop"
          class="mb-2"
          :label="prop"
          :v-model="affSubs[prop]"
          :value="affSubs[prop]"
          prepend-inner-icon="mdi-pen"
          clearable
          flat
          dense
          solo-inverted
          hide-details
          maxlength="128"
          @input="refreshURL(prop, $event)"
          @click:clear="refreshURL(prop, $event)"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { reactive } from '@vue/composition-api';
import _ from 'lodash';

export default {
  name: 'aff-sub',
  setup() {
    const affSubs = reactive({
      aff_sub: '',
      aff_sub1: '',
      aff_sub2: '',
      aff_sub3: '',
      aff_sub4: '',
      aff_sub5: '',
    });
    const affSubsParams = () => {
      let str = '';
      for (const prop in affSubs) {
        if (!_.isEmpty(affSubs[prop])) {
          str += '&' + prop + '=' + encodeURIComponent(affSubs[prop]);
        }
      }
      return str;
    };
    return { affSubs, affSubsParams };
  },
  methods: {
    refreshURL(key, value) {
      this.affSubs[key] = value;
      this.$emit('input', this.affSubsParams());
    },
  },
};
</script>
<style lang="scss">
.border {
  border: 1px solid #808080;
  border-radius: 4px;
  max-width: 400px;
}
</style>

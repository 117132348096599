<template>
  <v-container fluid>
    <v-layout row wrap justify="center">
      <v-flex xs12 md12>
        <v-card class="mx-auto mb-0 elevation-0" rounded>
          <smartlink-filters @resetPagination="resetPagination()" />
          <v-divider class="mx-5"></v-divider>
          <v-card-text>
            <data-list
              ref="dataList"
              :items="Smartlinks.state.items"
              :rows-per-page="Smartlinks.state.rowsNumberList"
              :server-items-length="Smartlinks.state.total"
              @paginate="Smartlinks.actions.applyOptions({ value: $event })"
              :loading="Smartlinks.state.loading"
              :itemsNewList="Smartlinks.state.rowsNumberList"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="(item, index) in props.items"
                    :key="index"
                    cols="12"
                    sm="6"
                    md="6"
                    lg="4"
                    xl="3"
                  >
                    <smartlink-preview
                      :key="item._id"
                      :item="item"
                      @select="Smartlinks.actions.selectItem({ value: item })"
                    />
                  </v-col>
                </v-row>
              </template>
            </data-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { inject, watch, onMounted, reactive, toRefs, readon } from '@vue/composition-api';
import SmartlinkPreview from './SmartlinkPreview';
import SmartlinkFilters from './SmartlinkFilters';
import DataList from '../../Components/DataList';

export default {
  name: 'smartlink-list',
  components: {
    'smartlink-preview': SmartlinkPreview,
    'smartlink-filters': SmartlinkFilters,
    'data-list': DataList,
  },
  setup(props, context) {
    const { platform } = inject('Platform');
    const Smartlinks = inject('Smartlinks');
    onMounted(() => Smartlinks.actions.loadItems());
    return { platform, Smartlinks };
  },
  methods: {
    resetPagination() {
      this.$refs.dataList.resetPagination();
    },
  },
};
</script>
